import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, firstValueFrom } from 'rxjs';
import { AccountsService, UserResponseDTO } from 'src/app/generated-api-client';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { isPlatformBrowser } from '@angular/common';
import { ConversionTrackingService } from './conversion-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  accessToken$ = new Subject<string | null>();
  userLoggedOut$ = new Subject<void>();
  userLoggedIn$ = new Subject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialog,
    private api: AccountsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private conversionTracking: ConversionTrackingService
  ) {}

  public confirmLogout(): void {
    this.dialog
      .open(ConfirmDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.logout();
        }
      });
  }

  public logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    this.dialogRef.closeAll();
    firstValueFrom(this.api.apiAccountsLogoutPost()).finally(() => {
      this.router.navigate(['/auth/magic-login']);
      this.userLoggedOut$.next();
    });
    this.accessToken$.next(null);
  }

  public setAccessToken(accessToken: string): void {
    localStorage.setItem('access_token', accessToken);
    this.accessToken$.next(accessToken);
  }

  public getAccessToken(): string | null {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('access_token');
    }

    return null;
  }

  public isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  public setUser(user: UserResponseDTO): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public getUser(): UserResponseDTO | null {
    if (isPlatformBrowser(this.platformId)) {
      const userJson = localStorage.getItem('user');
      return JSON.parse(userJson!) as UserResponseDTO;
    }

    return null;
  }

  public loginRedirect(returnUrl?: string): void {
    firstValueFrom(this.dialogRef.afterAllClosed).then(() => {
      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        const user = this.getUser();
        if (user?.roles!.includes('Admin')) {
          this.router.navigate(['/backoffice/admin']);
        } else if (user?.roles!.includes('Packer')) {
          this.router.navigate(['/backoffice/packer']);
        } else if (user?.roles!.includes('Procurement')) {
          this.router.navigate(['/backoffice/procurement']);
        } else {
          this.router.navigate(['/user']);
        }
        this.userLoggedIn$.next();
      }
    });

    this.dialogRef.closeAll();

    this.conversionTracking.trackCompleteRegistration({
      email: this.getUser()!.email!,
    });
  }

  public goToLoginThenComeBack(): void {
    const returnUrl = this.router.routerState.snapshot.url;
    this.router.navigate(['/auth/magic-login'], { queryParams: { returnUrl } });
  }
}
